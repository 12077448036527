import React from 'react';
import { Carousel } from 'antd';
import Section from '../BlockElement/Section/Section';
import './Team.scss';
import { teamMembers } from '../../utils/TeamMembers'; 
import { ITeamMember } from '../../types/ITeamMember'; 
import Container from '../BlockElement/Container/Container'; 
import Heading from '../TextElement/Heading/Heading';
import Paragraph from '../TextElement/Paragraph/Paragraph';

export default function Team() {
  return (
    <Section className="team-section">
      <Heading type='h2' className="section-title" items={[]} htmlFor={''}>Nasz zespół</Heading>
      <Container className="section-team">
        <Carousel
          arrows
          infinite
          slidesToShow={4}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {teamMembers.map((member: ITeamMember, index: number) => (
            <Container key={index} className="team-card">
              <Container className='icon--container'>
                <span className="material-symbols-outlined icon">account_circle</span>
              </Container>
              <Container className="overlay-text">
                <Heading type="h3" className="team-card-title" items={[]} htmlFor={''}>{member.name}</Heading>
                <Paragraph className="team-card-description" items={[]} htmlFor={''}>{member.description}</Paragraph>
              </Container>
            </Container>
          ))}
        </Carousel>
      </Container>
    </Section>
  );
}
