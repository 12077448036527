import { IReviewCardProps } from '../types/IReviewCardProps';

export const reviews: IReviewCardProps[] = [
    {
        text: `“
            Bardzo profesjonalna i miła obsługa. Zdecydowanie polecam :)
        ”`,
        background: 'opinion1'
    },
    {
        text: `“
            Pełen profesjonalizm.
            Z całego serca polecam również dla dzieci, swoją delikatnością 
            przyciąga nawet najbardziej strachliwego pacjenta
        ”`,
        background: 'opinion2'
    },
    {
        text: `“
            Bezbolesne fachowo i z uśmiechem. Pani doktor bardzo profesjonalnie podchodzi do swojej pracy, 
            każdy pacjent traktowany indywidualnie z troską.
        ”`,
        background: 'opinion1'
    },
    {
        text: `“
            Profesjonalny specjalista, wystawiający szybką i trafną diagnozę. 
            Wysokiej klasy sprawny chirurg wykonał rzadki zabieg zakończony sukcesem
        ”`,
        background: 'opinion2'
    },
];
