import { useNavigate } from "react-router-dom";
import Container from "../BlockElement/Container/Container";
import "./Service.scss";
import Link from "../MediaElement/Link/Link";

export default function Service({ title }: { title: string }) {
  const navigate = useNavigate();

  function normalizeString(input: string) {
    const polishCharsMap: { [key: string]: string } = {
      ą: "a",
      ć: "c",
      ę: "e",
      ł: "l",
      ń: "n",
      ó: "o",
      ś: "s",
      ź: "z",
      ż: "z",
      Ą: "A",
      Ć: "C",
      Ę: "E",
      Ł: "L",
      Ń: "N",
      Ó: "O",
      Ś: "S",
      Ź: "Z",
      Ż: "Z",
    };

    return input
      .split("")
      .map((char: string) => polishCharsMap[char] || char)
      .join("")
      .replace(/\s+/g, "_")
      .toLocaleLowerCase();
  }

  return (
    <Container className="service" onClick={() => navigate(`/uslugi/${normalizeString(title)}`)}>
      <span>{title}</span>
      <Link className="arrow" href={`/uslugi/${normalizeString(title)}`}>→</Link>
    </Container>
  );
}
