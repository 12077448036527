import React from "react";
import "./Footbar.scss";
import { IStructureElement } from "../../../types/IStructureElement";
import Footer from "../../BlockElement/Footer/Footer";
import logo from "../../../assets/logo.svg";
import Link from "../../MediaElement/Link/Link"; 
import Container from "../../BlockElement/Container/Container";
import { SiFacebook, SiInstagram } from 'react-icons/si'; 
import { CiPhone } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";

export default function Footbar({ id, className }: IStructureElement) {
    return(
        <Footer id={id} className={className}>
             <Container className="footer__content">
             <Link href="/" className="footer__logo">
                <img src={logo} alt="Logo" />
            </Link>
                <Container className="footer__details">
                <Container className="footer__contact">
                        <h3>Kontakt</h3>
                        <p className="icon-text">
                            <CiPhone className="icon" /> 42 715 33 89
                        </p>
                        <p className="icon-text">
                            <CiPhone className="icon" /> 571 224 075
                        </p>
                        <p className="icon-text">
                            <CiLocationOn className="icon" /> Staffa 10, 95-100 Zgierz
                        </p>
                    </Container>
                    <Container className="footer__hours">
                        <h3>Godziny otwarcia</h3>
                        <p>pon - czw <span>8.30 - 20.00</span></p>
                        <p>pt <span>8.30 - 14.30</span></p>
                        <p>sob - ndz <span>nieczynne</span></p>
                    </Container>
                    <Container className="footer__social-icons">
                    <Link href="https://www.facebook.com/share/14WJjyKDFs/?mibextid=LQQJ4d" target="_blank" >
                        <SiFacebook className="social-icon" />
                    </Link>
                    <Link href="https://www.instagram.com/pro.dentica?igsh=MWh2cXkwc2JvdmJqcg==" target="_blank" >
                        <SiInstagram className="social-icon" />
                    </Link>
                </Container>
                </Container>
            </Container>
        </Footer>
    );  
}