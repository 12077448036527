import { ITeamMember } from '../types/ITeamMember';

const member1: ITeamMember = {
  name: 'lek. dent. Magdalena Sordyl',
  image: '/img/card.png', 
  description: `
    Swoją wiedzę systematycznie podnosi biorąc udział w licznych kursach i szkoleniach
    z zakresu ortodoncji i protetyki stomatologicznej. W pracy klinicznej zajmuje się leczeniem ortodontycznym dorosłych i dzieci.
  `,
};

const member2: ITeamMember = {
  name: 'dr n. med. Bartosz Bielecki-Kowalski',
  image: '/img/card.png', 
  description: `
    Asystent oraz wykładowca w Uniwersyteckim Szpitalu Klinicznym im. Wojskowej Akademii 
    Medycznej w Klinice chirurgii szczękowo -twarzowej. 
    Nieustannie podnosi swoje kompetencje zawodowe i naukowe uczestnicząc w licznych kursach. 
  `,
};

const member3: ITeamMember = {
  name: 'lek. dent. Beata Dobrzańska',
  image: '/img/card.png',
  description: `
    Specjalizuje się w diagnostyce, leczeniu i profilaktyce chorób zębów, dziąseł oraz jamy ustnej. 
    Dzięki nowoczesnym technologiom i indywidualnemu podejściu dba o zdrowy i estetyczny uśmiech swoich pacjentów.
  `,
};

const member4: ITeamMember = {
  name: 'lek. dent. Magdalena Zając',
  image: '/img/card.png', 
  description: `
    Zajmuje się głównie leczeniem zachowawczym zarówno pacjentów dorosłych
    jak i dzieci. W pracy klinicznej wykorzystuje powiększenie pola zabiegowego w postaci lup stomatologicznych. 
    Nieustannie podnosi swoje kwalifikacje. Kładzie duży nacisk na komfort Pacjenta i miłą atmosferę podczas wizyty. 
  `,
};

export const teamMembers: ITeamMember[] = [member1, member2, member3, member4];
