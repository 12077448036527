import './Description.scss'
import Container from "../BlockElement/Container/Container";
import { IDescriptionElement } from '../../types/IDescriptionElement';
import Paragraph from '../TextElement/Paragraph/Paragraph';
import Heading from '../TextElement/Heading/Heading';

export default function Description({title, text, color}: IDescriptionElement) {
    return (
      <Container className={`description-container  ${color === 'blue' ? 'text-blue' : 'text-white'}`}>
        <Container className='text-block'>
          <Heading type="h2" className='description-header' items={[]} htmlFor={''}>{title}</Heading>
          <Paragraph htmlFor='' items={[]}>{text}</Paragraph>
        </Container>
      </Container>
    );
  }