import React from 'react';
import { Carousel } from 'antd';
import Section from '../BlockElement/Section/Section';
import './Opinions.scss';
import { reviews } from '../../utils/ReviewCard';
import Container from '../BlockElement/Container/Container'; 
import opinion1Image from '../../assets/opinion1.png';
import opinion2Image from '../../assets/opinion2.png';

export default function Opinions() {
  return (
    <Section className="opinions-section">
      <h2 className="section-title">Poznaj opinie naszych pacjentów</h2>
      <Carousel
        className="carousel"
        arrows
        dots={false}
        slidesToShow={2} 
        responsive={[
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1, 
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ]}
      >
        {reviews.map((review, index) => (
          <Container key={index} className="review-card">
            <img
              src={review.background === 'opinion1' ? opinion1Image : opinion2Image}
              alt="opinion background"
              className="review-card-image"
            />
            <Container className="overlay-text">
              <p className="review-card-text">{review.text}</p>
            </Container>
          </Container>
        ))}
      </Carousel>
    </Section>
  );
}
