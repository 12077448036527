import Section from '../BlockElement/Section/Section';
import './Hero.scss';
import Button from "../FormElement/Button/Button";
import Container from "../BlockElement/Container/Container";
import { FaCircleArrowRight } from "react-icons/fa6";
import Heading from '../TextElement/Heading/Heading';
import Link from '../MediaElement/Link/Link';

export default function Hero(){
    return (
        <Section>
            <Container className="hero__container">
                <Container className="hero__background">
                    <Heading type="h1" className="hero__text" items={[]} htmlFor={''}>Perfekcyjny uśmiech <br/>zaczyna się od <br/>zdrowych zębów</Heading>
                    <Heading type="h2" className="smaller__text" items={[]} htmlFor={''}>Kompleksowa opieka ortodontyczna <br/> i stomatologiczna</Heading>
                    <Container className="smaller__button">
                        <a href="https://felg.app/extcal/6650081390654545" target="_blank">
                            <Button className="blue small">Umów wizytę</Button>
                        </a>
                    </Container>
                    <Container className="floating__banner">
                        <Container className="arrow-container">
                            <Link href="/uslugi/leczenie_nakladkowe"><FaCircleArrowRight className="arrow" /></Link>
                        </Container>
                        <Container className="floating__banner-text">Leczenie<br />nakładkowe</Container>
                    </Container>
                </Container>
            </Container>
        </Section>
    );
}