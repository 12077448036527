import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Prices from './pages/Prices/Prices';
import Specification from './pages/Specification/Specification';

export default function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path='/cennik' element={<Prices />} /> */}
        <Route path='/uslugi/:service' element={<Specification />} />
      </Routes>
    </BrowserRouter>
  );
}